import React, { useState } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import SunEditor CSS


const TextEditor = ({val, change}) => {
  const applyTableColumnStyles = () => {
    const editorContainer = document.querySelector('.sun-editor-editable');
    const tables = editorContainer.querySelectorAll('table');
    
    // tables.forEach((table) => {
    //   let colgroup = table.querySelector('colgroup');
    //   if (!colgroup) {
    //     colgroup = document.createElement('colgroup');
    //     table.prepend(colgroup);
    //   }
    //   colgroup.innerHTML = '';
    //   const columnWidths = [150, 200, 100];
    //   for (let i = 0; i < columnWidths.length; i++) {
    //     const col = document.createElement('col');
    //     col.style.width = `${columnWidths[i]}px`;
    //     colgroup.appendChild(col);
    //   }
    //   const cells = table.querySelectorAll('td, th');
    //   cells.forEach((cell, index) => {
    //     const colIndex = index % columnWidths.length;
    //     cell.style.width = `${columnWidths[colIndex]}px`;
    //     cell.style.overflow = 'hidden';
    //     cell.style.wordWrap = 'break-word';
    //   });
    // });
  };
    return (
      <div>
        <SunEditor
          setOptions={{
            height: 200,
            buttonList: [
              ['undo', 'redo'],
              ['font', 'fontSize', 'formatBlock'],
              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
              ['fontColor', 'hiliteColor', 'textStyle'],
              ['removeFormat'],
              ['outdent', 'indent'],
              ['align', 'horizontalRule', 'list', 'table'],
              ['link', 'image', 'video', 'audio'],
              ['fullScreen', 'showBlocks', 'codeView'],
              ['preview', 'print'],
            ],
            table: {
              tableResizable: false, // Disable table resizing
            },
          }}
          onChange={e => (change(e), applyTableColumnStyles())}
          defaultValue={val}
        />
      </div>
    );
};
export default TextEditor;
